import React from 'react';
import {useSelector} from "react-redux";

const ImpersonateMessage = () => {
    const {manager} = useSelector(state => state.impersonate);
    const {userAdmission} = useSelector(state => state.main);

    return (
        <div className="impersonate-message">
            {manager.firstName} {manager.lastName} - logged into patient portal of {userAdmission.name} {userAdmission.surname}
        </div>
    )
}

export default ImpersonateMessage;