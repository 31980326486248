import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    loadingText: '',
    loadingError: '',
    userLoggedIn: false,
    userAdmission: false,
    userAdmissionTasks: [],
    currentDocument: false,
    currentMessage: false,
    documentProcessing: false,
    hospitalConfig: false,
    introDone: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CURRENT_DOCUMENT_DATA:
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    documentData: action.data
                },
            }
        case actionTypes.SET_INTRO_DONE:
            return {
                ...state,
                introDone: true,
            }
        case actionTypes.SET_IMPERSONATED_ADMISSION:
            return {
                ...state,
                loading: false,
                userAdmission: action.admission,
            };
        case actionTypes.DOCUMENT_PROCESSING_START:
            return {
                ...state,
                documentProcessing: true,
            };
        case actionTypes.DOCUMENT_PROCESSING_DONE:
            return {
                ...state,
                documentProcessing: false,
            };

        case actionTypes.LOADING_START:
            return {
                ...state,
                loading: true,
                loadingText: action.loadingText || '',
            };
        case actionTypes.LOADING_DONE:
            return {
                ...state,
                loading: false,
                loadingText: '',
            };
        case actionTypes.SET_ADMISSION:
            return {
                ...state,
                userLoggedIn: true,
                userAdmission: action.admission,
            };
        case actionTypes.SET_THEME_AND_HOSPITAL_CONFIG:
            return {
                ...state,
                hospitalConfig: action.hospitalConfig
            };
        case actionTypes.SET_ADMISSION_MESSAGE:
            return {
                ...state,
                loadingError: '',
                currentMessage: action.message
            };
        case actionTypes.SET_TASKS:
            return {
                ...state,
                userAdmissionTasks: action.tasks,
                currentDocument: false,
                introDone: false,
            };
        case actionTypes.SET_DOCUMENT:
            return {
                ...state,
                loadingError: '',
                currentDocument: action.document,
            };
        case actionTypes.UNSET_DOCUMENT:
            return {
                ...state,
                currentDocument: false,
            };
        case actionTypes.SET_DOCUMENT_LOADING_ERROR:
            return {
                ...state,
                loadingError: action.error,
                currentDocument: false,
            };
    }
    return state;
}

export default reducer;