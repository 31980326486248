import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Form from "./Form/Form";
import * as wizardActions from './store/actions/wizard';
import * as actionTypes from '../../store/actions/actionTypes';

const Document_v2 = ({ documentPage }) => {
    const dispatch = useDispatch();
    const formTopRef = useRef(null);
    const currentStep = useSelector(state => state.wizard.currentStep);
    const formValues = useSelector(state => state.form.wizard?.values);
    const previousValuesRef = useRef({}); // Initialize with an empty object
    const saveTimeoutRef = useRef(null);
    const currentDocument = useSelector(state => state.main.currentDocument);

    const scrollToTop = () => formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const dispatchWizardAutoSave = useCallback(() => {
        console.log('[Document_v2] Saving document...');
        dispatch(wizardActions.wizardAutoSave());
    }, [dispatch]);

    useEffect(() => {
        console.log('[Document_v2] useEffect([])');
        dispatch(wizardActions.wizardInitEdit(documentPage, currentDocument.documentData));

        return () => {
            console.log('[Document_v2] Cleanup');
            dispatch(wizardActions.wizardSave(false));
            dispatch({ type: actionTypes.REVIEW_RESET });
        };
    }, [dispatch, documentPage, currentDocument.documentData]);

    useEffect(() => {
        console.log('[Document_v2] useEffect([documentPage])');
        if (currentStep !== parseInt(documentPage)) {
            scrollToTop();
            dispatch(wizardActions.wizardChangeStep(documentPage));
            dispatch(wizardActions.wizardSave(false));
        }
    }, [currentStep, documentPage, dispatch]);

    useEffect(() => {
        if (formValues) {
            const currentValuesString = JSON.stringify(formValues);
            const previousValuesString = JSON.stringify(previousValuesRef.current);

            // Log values for debugging
            console.log('[Document_v2] Form values:', formValues);
            console.log('[Document_v2] Previous values:', previousValuesRef.current);

            // Only save if values have changed
            if (previousValuesString && currentValuesString !== previousValuesString) {
                console.log('[Document_v2] Form values changed. Scheduling save...');

                // Clear the existing timeout
                if (saveTimeoutRef.current) {
                    clearTimeout(saveTimeoutRef.current);
                }

                // Set a new timeout to save after 3 seconds
                saveTimeoutRef.current = setTimeout(() => {
                    dispatchWizardAutoSave();
                }, 3000); // 3 seconds

                // Update previous values
                previousValuesRef.current = formValues;
            } else if (!previousValuesRef.current) {
                // Initialize previous values on the first change
                previousValuesRef.current = formValues;
            }
        }
    }, [formValues, dispatchWizardAutoSave]);

    return (
        <>
            <div ref={formTopRef} />
            <Form onSubmit={dispatchWizardAutoSave} />
        </>
    );
};

export default Document_v2;
