import React from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import 'filepond/dist/filepond.min.css';
import {useSelector} from "react-redux";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUploader = () => {
    const {files, documentHash, admissionDocumentType} = useSelector(state => state.review);
    const authToken = useSelector(state => state.main.userAdmission.token);
    const filesConfig = files.map(file => ({
            source: `${process.env.REACT_APP_API_PATH}${file.source}`,
            options: {
                type: "local"
            }
        }));
    // console.log('FileUploader filesConfig', filesConfig);

    const handleRevertFile = async (file) => {
        // console.log('handleRevertFile', file);
        const request = new XMLHttpRequest();
        request.open('DELETE', `${process.env.REACT_APP_API_PATH}/api/patient/file/${file.serverId}/z`);
        request.setRequestHeader('Authorization', authToken);
        request.send();
    }

    const handleRemoveFile = async (error, file) => {
        // console.log('handleRemoveFile', file);
        if (typeof file.source === 'string') {
            const request = new XMLHttpRequest();
            request.open('DELETE', file.source);
            request.setRequestHeader('Authorization', authToken);
            request.send();
        }
    };

    const serverConfig = {
        revert: null,
        load: async (source, load, error, progress, abort, headers) => {
            const request = new XMLHttpRequest();
            request.open('GET', source)
            request.setRequestHeader('Authorization', authToken);
            request.responseType = 'blob';

            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    load(request.response);
                } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                }
            };

            request.send();
        },
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            // custom headers not working with async function, so I override default process function
            // code is from the link above, Authorization header added
            // https://pqina.nl/filepond/docs/patterns/api/server/#configuration

            // console.log('Process', fieldName, file, metadata, load, error, progress, abort, transfer, options);
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            const request = new XMLHttpRequest();
            request.open('POST', `${process.env.REACT_APP_API_PATH}/api/patient/upload/${documentHash}`);
            request.setRequestHeader('Authorization', authToken);

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                }
            };
        },
    };

    // const serverConfig = {};

    // todo: move to server side settings
    const renderText = () => {
            return (
                <>
                    <h2 className="mb-2">{admissionDocumentType.fileUploading_reviewHeader}
                    </h2>
                    <p dangerouslySetInnerHTML={{__html: admissionDocumentType.fileUploading_reviewBody}} />
                </>
            )
    }

    return (
        <div className="card mb-3">
            <div className="card mb-3">
                <div className="card-header row">
                    <div className="col-12">
                        {renderText()}

                        <FilePond
                            files={filesConfig}
                            server={serverConfig}
                            stylePanelLayout="compact"
                            allowMultiple={true}
                            name="fileUpload1"
                            onprocessfilerevert={handleRevertFile}
                            onremovefile={handleRemoveFile}
                            labelIdle={`<div class="custom-file"><div class="custom-file-label">
                                                <span class="align-content">
                                                    <span class="icon icon-drop-file"></span>
                                                    <span class="text-wrap">
                                                        <span class="main-text">${admissionDocumentType.fileUploading_reviewBtnTitle}</span>
                                                        <span class="note-text">Supported file type: JPG, PNG, TIFF or PDF</span>
                                                    </span>
                                                </span>
                                            </div></div>`}/>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileUploader;